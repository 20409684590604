addEventListener('DOMContentLoaded', () => {
  const attorneyItems = document.querySelectorAll('.attorney-item');
  const modalWindow = document.querySelector('.modal-attorney');

  const openModalWindow = (attorneyItem) => {
    // Information from Attorney Items
    let attorneyiItemName = attorneyItem.querySelector('p');
    let attorneyiItemDescription = attorneyItem.querySelector('.description');
    let attorneyiItemPosition = attorneyItem.querySelector('.position');
    let attorneyiItemImage = attorneyItem.querySelector('img');

    // Put information in Modal Window
    let modalWindowAttorneyName = document.querySelector('.modal-attorney-name').innerHTML = attorneyiItemName.innerHTML;
    let modalWindowAttorneyDescription = document.querySelector('.modal-attorney-about').innerHTML = attorneyiItemDescription.innerHTML;
    let modalWindowAttorneyPosition = document.querySelector('.modal-attorney-position').innerHTML = attorneyiItemPosition.innerHTML;
    const modalAttorneyImage = document.querySelector('.modal-attorney-image img');
    let imageSrc = attorneyiItemImage.getAttribute('src');
    let imageSrcSet = attorneyiItemImage.getAttribute('srcset');
    modalAttorneyImage.setAttribute('src', imageSrc);
    modalAttorneyImage.setAttribute('srcset', imageSrcSet);
    modalWindow.classList.add('block');
    document.body.style.overflow = 'hidden';
  }

  const closeModalWindow = () => {
    modalWindow.classList.remove('block');
    document.body.style.overflow = null;
  }

  if (attorneyItems != null && attorneyItems != undefined) {
    attorneyItems.forEach(attorneyItem => {
      attorneyItem.addEventListener('click', () => {
        openModalWindow(attorneyItem);
      });
    });
  }

  const btnClose = document.querySelector('.modal-attorney-close');
  if (btnClose != null && btnClose != undefined) {
    btnClose.addEventListener('click', () => {
      closeModalWindow();
    })
  }

  const modalWindowWrapper = document.querySelector('.modal-attorney');
  modalWindowWrapper.addEventListener('click', (e) => {
    if (e.target === modalWindowWrapper) {
      closeModalWindow();
    }
  })
})